// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyDdnKEo45irEHAV6ib55sJFjkIffQHJ7-o",
    authDomain: "get7-f3022.firebaseapp.com",
    projectId: "get7-f3022",
    storageBucket: "get7-f3022.appspot.com",
    messagingSenderId: "326601898306",
    appId: "1:326601898306:web:7f4b98195d93302db130db",
    measurementId: "G-DZGMF46H8B"
  };

const app = initializeApp(firebaseConfig);

// Initialize AppCheck
let appCheck;
try {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LdTW2EqAAAAAFuyqDD_Eo1L09FtulNhjiJqtrYa'),
    isTokenAutoRefreshEnabled: true
  });
  console.log('AppCheck initialized successfully');
} catch (error) {
  console.error('Error initializing AppCheck:', error);
  appCheck = null;
}

const auth = getAuth(app);

// Set persistence to local
setPersistence(auth, browserLocalPersistence);

const db = getFirestore(app);

export { app, auth, db, appCheck };
