<template>
  <div v-if="show" class="vault-door-container">
    <div class="vault-door">
      <div class="door-frame"></div>
      <div class="door">
        <div class="wheel">
          <div class="wheel-spoke"></div>
          <div class="wheel-spoke"></div>
          <div class="wheel-spoke"></div>
        </div>
        <div class="bolts">
          <div class="bolt" v-for="n in 8" :key="n"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VaultDoorAnimation',
  props: {
    show: Boolean
  }
}
</script>

<style scoped>
.vault-door-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

.vault-door {
  width: 100vw;
  height: 100vh;
  position: relative;
  perspective: 2000px;
}

.door-frame {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 40px solid #444;
  box-sizing: border-box;
}

.door {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #7a7a7a 0%, #b8b8b8 50%, #7a7a7a 100%);
  transform-origin: left;
  animation: openDoor 2.5s forwards;
  overflow: hidden;
}

.wheel {
  position: absolute;
  width: 240px;
  height: 240px;
  background-color: #333;
  border-radius: 50%;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
}

.wheel-spoke {
  position: absolute;
  width: 20px;
  height: 200px;
  background-color: #555;
  top: 20px;
  left: 110px;
}

.wheel-spoke:nth-child(2) {
  transform: rotate(60deg);
}

.wheel-spoke:nth-child(3) {
  transform: rotate(-60deg);
}

.bolts {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bolt {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #555;
  border-radius: 50%;
  border: 10px solid #333;
}

.bolt:nth-child(1) { top: 40px; left: 40px; }
.bolt:nth-child(2) { top: 40px; right: 40px; }
.bolt:nth-child(3) { bottom: 40px; left: 40px; }
.bolt:nth-child(4) { bottom: 40px; right: 40px; }
.bolt:nth-child(5) { top: 50%; left: 40px; transform: translateY(-50%); }
.bolt:nth-child(6) { top: 50%; right: 40px; transform: translateY(-50%); }
.bolt:nth-child(7) { left: 50%; top: 40px; transform: translateX(-50%); }
.bolt:nth-child(8) { left: 50%; bottom: 40px; transform: translateX(-50%); }

@keyframes openDoor {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotateWheel {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(-360deg);
  }
}

.door .wheel {
  animation: rotateWheel 2.5s linear;
}
</style>
