<template>
  <div class="weekly-leaderboard-container">
    <div class="leaderboard-box">
      <h2>Weekly Leaderboard</h2>
      <p>For the week of {{ formatDate(currentWeekStart) }}</p>
      <div class="navigation-buttons">
        <button @click="goToHome" class="nav-button">Home</button>
        <button v-if="hasPlayedToday" @click="showTodayStats" class="nav-button">Today's Stats</button>
      </div>

      <div v-if="userRank" class="user-rank">
        <h3 @click="showCurrentUserScores" class="clickable">My Rank: {{ userRank }}</h3>
        <p>Score: {{ userScore }}</p>
      </div>

      <div class="leaderboard-list">
        <h2>Top 25</h2>
        <div v-for="(player) in topPlayers" :key="player.uid" class="player-row">
          <div class="rank">{{ player.rank }}</div>
          <div class="player-info">
            <span class="username" @click="showUserScores(player)">{{ player.username }}</span>
          </div>
          <div class="score">{{ player.weeklyScore }}</div>
        </div>
      </div>
      
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <h3>{{ selectedUser.username }}'s Scores</h3>
          
          <!-- Add Trophy Case -->
          <div v-if="hasTrophies" class="trophy-case">
            <h4>Trophy Case</h4>
            <div class="medals">
              <span v-for="n in trophies.firstPlaceFinishes" :key="'gold'+n" class="medal gold">
                <i class="fas fa-medal"></i>
              </span>
              <span v-for="n in trophies.secondPlaceFinishes" :key="'silver'+n" class="medal silver">
                <i class="fas fa-medal"></i>
              </span>
              <span v-for="n in trophies.thirdPlaceFinishes" :key="'bronze'+n" class="medal bronze">
                <i class="fas fa-medal"></i>
              </span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Game Date</th>
                <th>Score</th>
                <th>Bombs Hit</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="score in userScores" :key="score.gameId">
                <tr class="game-row">
                  <td>{{ formatDate(score.gameDate) }}</td>
                  <td>{{ score.score }}</td>
                  <td>
                    <span v-for="n in score.bombsHit" :key="n" class="bomb-icon">💣</span>
                  </td>
                </tr>
                <tr class="question-row">
                  <td colspan="3" class="question-text">{{ score.questionText }}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <button class="close-button" @click="closeModal">Close</button>
        </div>
      </div>

      <!-- Add this new modal for Today's Stats -->
      <div v-if="showStatsModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeStatsModal">&times;</span>
          <h3>Today's Community Stats</h3>
          <div v-if="todayStats">
            <p>Got7: {{ calculatePercentage(todayStats.allCorrect, todayStats.count) }}%</p>
            <p>Banked Points: {{ calculatePercentage(todayStats.banked, todayStats.count) }}%</p>
            <p>
              <span v-for="n in 3" :key="n" class="bomb-icon">💣</span>
              : {{ calculatePercentage(todayStats.bombs, todayStats.count) }}%
            </p>
          </div>
          <div v-else>
            <p>No stats available for today.</p>
          </div>
          <button class="close-button" @click="closeStatsModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startOfWeek, subDays, addDays, endOfWeek, format, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { db, auth } from '../firebase';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'WeeklyLeaderboardView',
  data() {
    return {
      leaderboardData: null,
      topPlayers: [],
      userRank: null,
      userScore: 0,
      currentWeekStart: null,
      showModal: false,
      selectedUser: null,
      userScores: [],
      gameInfo: {}, // Store game information here
      totalPlayers: 0,
      showStatsModal: false,
      todayStats: null,
      hasPlayedToday: false,
      todayGameId: null, // Add this to store today's gameId
      trophies: {
        firstPlaceFinishes: 0,
        secondPlaceFinishes: 0,
        thirdPlaceFinishes: 0,
      },
    };
  },
  mounted() {
    this.fetchLeaderboardData();
    this.fetchGameIdsForCurrentWeek(); // Fetch game information once
    this.checkIfPlayedToday();
  },
  computed: {
    hasTrophies() {
      return this.trophies.firstPlaceFinishes > 0 ||
             this.trophies.secondPlaceFinishes > 0 ||
             this.trophies.thirdPlaceFinishes > 0;
    },
  },
  methods: {
    async fetchLeaderboardData() {
      try {
        const timeZone = 'America/New_York';
        const now = new Date();
        
        // Format current time in New York timezone
        const nowNY = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");
        
        // Parse the formatted string back to a Date object
        const nowNYDate = new Date(nowNY);

        // Find the start of the week (Monday at 6:00 AM ET)
        let startOfWeekNY = startOfWeek(nowNYDate, { weekStartsOn: 1 });
        startOfWeekNY.setHours(6, 0, 0, 0); // Set to 6:00 AM ET

        // If it's before 6:00 AM ET, use last week's leaderboard
        if (nowNYDate < startOfWeekNY) {
          startOfWeekNY = subDays(startOfWeekNY, 7);
        }

        this.currentWeekStart = startOfWeekNY;

        // Format the date for the Firestore document ID
        const leaderboardId = formatInTimeZone(startOfWeekNY, timeZone, 'yyyy-MM-dd');

        

        const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);
        const leaderboardDoc = await getDoc(leaderboardRef);

        if (leaderboardDoc.exists()) {
          this.leaderboardData = leaderboardDoc.data();
          
          
          if (this.leaderboardData && this.leaderboardData.scores) {
            const scoresArray = Array.isArray(this.leaderboardData.scores) 
              ? this.leaderboardData.scores 
              : Object.entries(this.leaderboardData.scores).map(([uid, data]) => ({
                  uid,
                  ...data
                }));

            // Fetch all usernames
            const usernames = await this.fetchUsernames(scoresArray.map(score => score.uid));

            // Sort players by score and assign ranks
            const sortedPlayers = scoresArray
              .map(player => ({
                ...player,
                username: usernames[player.uid] || player.username || 'Anonymous'
              }))
              .sort((a, b) => b.weeklyScore - a.weeklyScore);

            this.totalPlayers = sortedPlayers.length;

            let currentRank = 1;
            let previousScore = null;

            this.topPlayers = sortedPlayers.slice(0, 25).map((player, index) => {
              if (player.weeklyScore !== previousScore) {
                currentRank = index + 1;
              }
              previousScore = player.weeklyScore;
              return { ...player, rank: currentRank };
            });

            // Calculate user rank and score
            const currentUser = auth.currentUser;
            if (currentUser) {
              const userScore = sortedPlayers.find(score => score.uid === currentUser.uid);
              if (userScore) {
                this.userScore = userScore.weeklyScore;
                this.userRank = sortedPlayers.findIndex(score => score.uid === currentUser.uid) + 1;
                
                // Adjust user rank for ties
                for (let i = 0; i < this.userRank - 1; i++) {
                  if (sortedPlayers[i].weeklyScore === this.userScore) {
                    this.userRank = i + 1;
                    break;
                  }
                }
              }
            }
          } else {
            
            this.topPlayers = [];
            this.userRank = null;
            this.userScore = 0;
            this.totalPlayers = 0;
          }
        } else {
          
          this.leaderboardData = null;
          this.topPlayers = [];
          this.userRank = null;
          this.userScore = 0;
          this.totalPlayers = 0;
        }
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    },

    async fetchUsernames(uids) {
      const usernamesMap = {};
      const usersRef = collection(db, 'users');
      const userDocs = await getDocs(usersRef);
      
      userDocs.forEach(doc => {
        if (uids.includes(doc.id)) {
          usernamesMap[doc.id] = doc.data().username || 'Anonymous';
        }
      });
      
      return usernamesMap;
    },

    navigateToPreviousWeek() {
      if (this.currentWeekStart) {
        this.currentWeekStart = subDays(this.currentWeekStart, 7);
        this.fetchLeaderboardData();
      }
    },
    navigateToNextWeek() {
      if (this.currentWeekStart) {
        this.currentWeekStart = addDays(this.currentWeekStart, 7);
        this.fetchLeaderboardData();
      }
    },
    goToHome() {
      this.$router.push('/');
    },
    async fetchGameIdsForCurrentWeek() {
      const startOfWeekNY = this.currentWeekStart;
      const endOfWeekNY = endOfWeek(startOfWeekNY, { weekStartsOn: 1 });
      endOfWeekNY.setHours(23, 59, 59, 999);

      const gamesRef = collection(db, 'games');
      const gamesQuery = query(
        gamesRef,
        where('liveDate', '>=', startOfWeekNY),
        where('liveDate', '<=', endOfWeekNY)
      );
      const gamesSnapshot = await getDocs(gamesQuery);

      const gameInfo = {};
      gamesSnapshot.forEach(doc => {
        const data = doc.data();
        gameInfo[doc.id] = {
          gameDate: data.liveDate,
          questionText: data.questionText,
        };
      });

      this.gameInfo = gameInfo;
      return Object.keys(gameInfo);
    },
    async fetchUserScores(user) {
      const gameIds = await this.fetchGameIdsForCurrentWeek();
      const scores = [];
      for (const gameId of gameIds) {
        const scoreRef = doc(db, `users/${user.uid}/scores/${gameId}`);
        const scoreDoc = await getDoc(scoreRef);
        if (scoreDoc.exists()) {
          const scoreData = scoreDoc.data();
          const gameData = this.gameInfo[gameId];
          scores.push({
            gameId,
            gameDate: gameData.gameDate,
            questionText: gameData.questionText,
            score: scoreData.score,
            bombsHit: scoreData.bombsHit || 0, // Add bombs hit
          });
        }
      }
      return scores;
    },
    async showUserScores(user) {
      
      this.selectedUser = user;
      this.userScores = await this.fetchUserScores(user);
      await this.fetchUserTrophies(user.uid);
      this.showModal = true;
    },
    async showCurrentUserScores() {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No current user logged in');
        return;
      }
      await this.showUserScores(currentUser);
    },
    closeModal() {
      this.showModal = false;
      this.selectedUser = null;
      this.userScores = [];
    },
    formatDate(date) {
      if (date instanceof Object && date.seconds) {
        // Convert Firestore Timestamp to JavaScript Date
        date = new Date(date.seconds * 1000);
      }

      if (!(date instanceof Date) || isNaN(date)) {
        console.error('Invalid date:', date);
        return 'Invalid Date';
      }

      return format(date, 'MMMM d');
    },
    async showTodayStats() {
      if (!this.todayGameId) {
        console.error('No gameId for today');
        return;
      }

      const statsRef = doc(db, 'analytics', this.todayGameId); // Use the stored gameId
      const statsDoc = await getDoc(statsRef);

      if (statsDoc.exists()) {
        this.todayStats = statsDoc.data();
      } else {
        this.todayStats = null;
      }

      this.showStatsModal = true;
    },

    closeStatsModal() {
      this.showStatsModal = false;
      this.todayStats = null;
    },

    calculatePercentage(value, total) {
      if (total === 0) return 0;
      return ((value / total) * 100).toFixed(1);
    },

    async checkIfPlayedToday() {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        this.hasPlayedToday = false;
        return;
      }

      const today = startOfDay(new Date());
      
      
      const gamesRef = collection(db, 'games');
      const gamesQuery = query(gamesRef, where('liveDate', '>=', today));
      const gamesSnapshot = await getDocs(gamesQuery);

      if (!gamesSnapshot.empty) {
        this.todayGameId = gamesSnapshot.docs[0].id; // Store the gameId
        const scoreRef = doc(db, `users/${currentUser.uid}/scores/${this.todayGameId}`);
        const scoreDoc = await getDoc(scoreRef);

        this.hasPlayedToday = scoreDoc.exists();
      } else {
        this.hasPlayedToday = false;
      }
    },

    async fetchUserTrophies(uid) {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.trophies = {
          firstPlaceFinishes: userData.firstPlaceFinishes || 0,
          secondPlaceFinishes: userData.secondPlaceFinishes || 0,
          thirdPlaceFinishes: userData.thirdPlaceFinishes || 0,
        };
      } else {
        this.trophies = {
          firstPlaceFinishes: 0,
          secondPlaceFinishes: 0,
          thirdPlaceFinishes: 0,
        };
      }
    },
  }
};
</script>

<style scoped>
.weekly-leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.leaderboard-box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.nav-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.nav-button:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.user-rank {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-rank h3 {
  margin: 0;
  color: #333;
}

.user-rank p {
  margin: 5px 0 0;
  color: #666;
}

.leaderboard-list {
  max-height: 400px;
  overflow-y: auto;
}

.player-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.rank {
  font-weight: bold;
  width: 30px;
}

.player-info {
  flex-grow: 1;
  margin-left: 10px;
}

.username {
  font-weight: bold;
  cursor: pointer; /* Change cursor to pointer */
  color: #007BFF; /* Add a color to indicate it's clickable */
  text-decoration: underline; /* Underline the text */
}

.username:hover {
  color: #0056b3; /* Change color on hover */
  text-decoration: none; /* Remove underline on hover */
}

.score {
  font-weight: bold;
  color: #4CAF50;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: none; /* Remove border from all cells */
}

th {
  background-color: #f4f4f4;
}

td {
  background-color: #fff;
}

.game-row td {
  border-bottom: none; /* Remove border from game rows */
}

.question-row td {
  border-bottom: 1px solid #ddd; /* Add border to question rows */
}

.bomb-icon {
  font-size: 1.2em;
  margin-right: 2px;
}

.question-text {
  font-style: italic;
  color: #666;
  padding-top: 5px;
  padding-bottom: 10px;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #45a049;
}

.clickable {
  cursor: pointer;
  color: #007BFF;
  text-decoration: underline;
}

.clickable:hover {
  color: #0056b3;
  text-decoration: none;
}

.trophy-case {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.medals {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.medal {
  font-size: 24px;
}

.gold {
  color: #FFD700;
}

.silver {
  color: #C0C0C0;
}

.bronze {
  color: #CD7F32;
}
</style>