<template>
  <div class="game-over-container">
    <div class="game-over-box">
      <h2>Game Over!</h2>
      <div class="score-info">
        <p>Your score for this game: <span class="score">{{ currentGameScore }}</span></p>
        <p>Question: {{ questionText }}</p>
        <p>Correct Answers: <span class="correct-answers">{{ correctAnswers.join(', ') }}</span></p>
        <p>Bombs: <span class="bombs">{{ bombs.join(', ') }}</span></p>
        <p>Bombs Hit: <span class="bombs-hit">{{ bombsHit }}</span></p>
      </div>
      <div v-if="!user" class="anonymous-user-message">
      <button @click="goToLoginRegister" class="btn btn-primary login-register-btn">Login or register to save your score to the leaderboard!</button>
    </div>
      <div class="buttons">
        <button @click="goToWeeklyLeaderboard" class="btn btn-primary">Weekly Leaderboard</button>
        <button @click="showTodayStats" class="btn btn-secondary">Today's Stats</button>
        <button @click="shareScore" class="btn btn-tertiary share-score-btn">Share Score</button>
      </div>
    </div>

    <!-- Add this new modal for Today's Stats -->
    <div v-if="showStatsModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeStatsModal">&times;</span>
        <h3>Today's Community Stats</h3>
        <div v-if="todayStats">
          <p>Got7: {{ calculatePercentage(todayStats.allCorrect, todayStats.count) }}%</p>
          <p>Banked Points: {{ calculatePercentage(todayStats.banked, todayStats.count) }}%</p>
          <p>
            <span v-for="n in 3" :key="n" class="bomb-icon">💣</span>
            : {{ calculatePercentage(todayStats.bombs, todayStats.count) }}%
          </p>
        </div>
        <div v-else>
          <p>No stats available for today.</p>
        </div>
        <button class="close-button" @click="closeStatsModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebase';
import { doc, getDoc, runTransaction } from 'firebase/firestore';
import { startOfWeek, subDays, parse, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export default {
  name: 'GameOverView',
  props: {
    currentGameScore: {
      type: Number,
      required: true
    },
    questionText: {
      type: String,
      required: true
    },
    correctAnswers: {
      type: Array,
      required: true
    },
    bombs: {
      type: Array,
      required: true
    },
    bombsHit: {
      type: Number,
      required: true
    },
    gameId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const weeklyScore = ref(0);
    const showStatsModal = ref(false);
    const todayStats = ref(null);

    const fetchWeeklyScore = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const timeZone = 'America/New_York';
          const now = new Date();
          
          // Format current time in New York timezone
          const nowNY = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");
          
          // Parse the formatted string back to a Date object
          const nowNYDate = parse(nowNY, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());

          // Find the start of the week (Monday at 12:00 PM ET)
          let startOfWeekNY = startOfWeek(nowNYDate, { weekStartsOn: 1 });
          startOfWeekNY.setHours(12, 0, 0, 0); // Set to noon ET

          // If it's before noon ET, use last week's leaderboard
          if (nowNYDate < startOfWeekNY) {
            startOfWeekNY = subDays(startOfWeekNY, 7);
          }

          // Format the date for the Firestore document ID
          const leaderboardId = formatInTimeZone(startOfWeekNY, timeZone, 'yyyy-MM-dd');

          const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);
          const leaderboardDoc = await getDoc(leaderboardRef);

          if (leaderboardDoc.exists()) {
            const leaderboardData = leaderboardDoc.data();
            if (leaderboardData && leaderboardData.scores) {
              // Convert scores object to array if it's not already an array
              const scoresArray = Array.isArray(leaderboardData.scores) 
                ? leaderboardData.scores 
                : Object.entries(leaderboardData.scores).map(([uid, data]) => ({
                    uid,
                    ...data
                  }));

              const userScore = scoresArray.find(score => score.uid === user.uid);
              if (userScore) {
                weeklyScore.value = userScore.weeklyScore;
              }
            }
          }
        } catch (error) {
          console.error('Error fetching weekly score:', error);
        }
      }
    };

    const goToWeeklyLeaderboard = () => {
      router.push('/weekly-leaderboard');
    };

    const goToMyStats = () => {
      router.push('/my-stats');
    };

    const goToLoginRegister = () => {
      router.push('/login');
    };

    const shareScore = async () => {
      const gameDate = format(new Date(), 'MMMM d');
      const bombsHitText = props.bombsHit > 0 ? '💣'.repeat(props.bombsHit) : '0️⃣'
      const message = `Get7 - ${gameDate}\nCategory: ${props.questionText}\nScore: ${props.currentGameScore}\nBombs Hit: ${bombsHitText}`;
      
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Get7 Game Score',
            text: message,
            url: 'https://get7game.com'
          });
          
          // Use a transaction to safely increment or initialize timesShared
          const statsRef = doc(db, 'analytics', props.gameId);
          await runTransaction(db, async (transaction) => {
            const statsDoc = await transaction.get(statsRef);
            if (!statsDoc.exists()) {
              transaction.set(statsRef, { timesShared: 1 });
            } else {
              const currentTimesShared = statsDoc.data().timesShared || 0;
              transaction.update(statsRef, { timesShared: currentTimesShared + 1 });
            }
          });

        } catch (error) {
          console.error('Error sharing or updating share count');
        }
      } else {
        // Fallback for browsers that do not support the Web Share API
        alert('Sharing is not supported in this browser. Please copy the text manually: ' + message);
      }
    };

    const showTodayStats = async () => {
      const statsRef = doc(db, 'analytics', props.gameId);
      const statsDoc = await getDoc(statsRef);

      if (statsDoc.exists()) {
        todayStats.value = statsDoc.data();
      } else {
        todayStats.value = null;
      }

      showStatsModal.value = true;
    };

    const closeStatsModal = () => {
      showStatsModal.value = false;
      todayStats.value = null;
    };

    const calculatePercentage = (value, total) => {
      if (total === 0) return 0;
      return ((value / total) * 100).toFixed(1);
    };

    onMounted(async () => {
      await fetchWeeklyScore();
    });

    return {
      weeklyScore,
      goToWeeklyLeaderboard,
      goToMyStats,
      shareScore,
      goToLoginRegister,
      showTodayStats,
      closeStatsModal,
      showStatsModal,
      todayStats,
      calculatePercentage,
    };
  }
};
</script>

<style scoped>
.game-over-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.game-over-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.score-info {
  margin-bottom: 30px;
}

p {
  font-size: 18px;
  color: #666;
  margin: 10px 0;
}

.score {
  font-weight: bold;
  color: #764ba2;
  font-size: 24px;
}

.correct-answers {
  font-weight: bold;
  color: #764ba2;
  font-size: 24px;
}

.bombs {
  font-weight: bold;
  color: #764ba2;
  font-size: 24px;
}

.bombs-hit {
  font-weight: bold;
  color: #764ba2;
  font-size: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.btn-primary {
  background-color: #764ba2;
  color: white;
}

.btn-primary:hover {
  background-color: #5e3d82;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-tertiary {
  background-color: #28a745;
  color: white;
}

.btn-tertiary:hover {
  background-color: #218838;
}

.login-register-btn {
  margin-bottom: 10px;
}

.share-score-btn {
  font-size: 20px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.bomb-icon {
  font-size: 1.2em;
  margin-right: 2px;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #45a049;
}
</style>
